"use client";
import { useMemo, useState } from "react";
import axios from "axios";
import Link from "next/link";
import BlogSectionLanding from "@/app/blog/components/components/BlogSection";
import classes from "./BlogsSection.module.css";

const BlogsSection = () => {
  const [sortedArticles, setSortedArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSortedArticles = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.NEXT_PUBLIC_SERVER_API}/api/blogs`, {
        params: {
          tag: "Sustainability,Interior Design,Seller Spotlight",
          limit: 4,
        },
      })
      .then((res) => {
        setSortedArticles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };

  useMemo(() => {
    fetchSortedArticles();
  }, []);

  return (
    <>
      <div className={classes.main}>
        <section className={classes.headerBox}>
          <h2 className={classes.header}>Your Interior&apos;s Favorite Blog</h2>
          <Link href="/blog" className={classes.seeAll}>
            Read New Articles
          </Link>
        </section>
        <section className={classes.center}>
          {!loading &&
            sortedArticles?.length > 0 &&
            sortedArticles?.map((article, idx) => (
              <span key={idx} style={{ position: "relative" }}>
                <BlogSectionLanding article={article} idx={idx} />
              </span>
            ))}
        </section>
      </div>
    </>
  );
};
export default BlogsSection;
