'use client';
import { useEffect, useMemo, useState, lazy } from 'react';
import { useRouter } from 'next/navigation';
import classes from './DiscoverSection.module.css';
import Listing from '@/components/UI/Carousels/Listing';
import PlaceHolderListingNew from '@/components/UI/Carousels/PlaceHolderListingNew';
import algoliasearch from 'algoliasearch';
const client =
	process.env.NEXT_PUBLIC_OG_DB === 'true'
		? algoliasearch(
				process.env.NEXT_PUBLIC_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
		  )
		: algoliasearch(
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_API_KEY
		  );
const DiscoverSection = (props) => {
	const { position } = props;
	const [displayListings, setDisplayListings] = useState([]);
	const [displayInformation, setDisplayInformation] = useState(null);
	const router = useRouter();

	const info = [
		{
			position: 1,
			title: 'Branded Sofas on Sale and Out Soon',
			linkText: 'Explore our collection',
			link: '/collections/seating/sofas?op=branded&discount=1',
			image: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/sofa_1.jpg?tr=w-1000',
			alt: 'Branded Sofas on Sale',
			credit: '',
			designer: false,
			categories: ['Sofas', 'Sectionals', 'Loveseats'],
			onSale: true,
			branded: true,
		},
		// {
		// 	position: 1,
		// 	title: 'Colors of Summer',
		// 	linkText: 'Explore outdoor furniture',
		// 	link: '/room/outdoor',
		// 	image:
		// 		'https://ik.imagekit.io/kuj9zqlrx0or/Static/colors_of_summer_4.jpg?tr=w-1000',
		// 	alt: 'Second hand outdoor furniture',
		// 	credit: '',
		// 	designer: false,
		// 	categories: [],
		// 	feature: 'outdoor',
		// 	onSale: false,
		// 	branded: true,
		// },
		{
			position: 2,
			title: 'Living Room Chairs',
			linkText: 'find some treasures',
			link: 'room/living_room?category=seating&option=armchairs',
			image:
				'https://ik.imagekit.io/kuj9zqlrx0or/Static/landing_page/mid_century_mobler_3.png?tr=w-1000',
			credit: '© Mid Century Mobler',
			alt: 'Mid Century Modern Furniture',
			designer: false,
			onSale: true,
			branded: true,
			categories: ['Accent Chairs', 'Arm Chairs'],
		},
	];

	useEffect(() => {
		const infoObj = info.find((item) => item.position === Number(position));
		setDisplayInformation(infoObj);
	}, [position]);

	const getListingsHandler = async (
		style,
		categories,
		designer,
		branded,
		onSale,
		feature
	) => {
		const index = client.initIndex(
			process.env.NEXT_PUBLIC_OG_DB === 'false'
				? 'kashew_listings'
				: `kashew_listings_branded_deals`
		);
		await index
			.search('', {
				hitsPerPage: 4,
				clickAnalytics: true,
				page: 0,
				filters: `status:active${
					categories?.length > 0
						? ` AND (${categories.map((category, idx) =>
								idx === 0
									? `newSchema.categories:"${category}" OR newSchema.subCategories:"${category}" OR newSchema.types:"${category}"`
									: ` OR newSchema.categories:"${category}" OR newSchema.subCategories:"${category}" OR newSchema.types:"${category}"`
						  )})`
						: ''
				}${
					style
						? ` AND velouAttributes.styles:"${style[0]
								.replace(/-/g, ' ')
								.replace(/\band\b/g, '&')
								.replace(' ', '_')}"`
						: ''
				}${designer ? ` AND designerExists:true` : ''}${
					branded ? ` AND brandExists:1` : ''
				}${onSale ? ` AND discountPercentage > 0` : ''}${
					feature ? ` AND velouAttributes.features:"${feature}"` : ''
				}`.replace(/,/g, ''),
			})
			.then(async (res) => {
				console.log('res.hits ', res.hits);
				setDisplayListings(res.hits);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useMemo(() => {
		if (displayInformation) {
			getListingsHandler(
				displayInformation.style,
				displayInformation.categories,
				displayInformation.designer,
				displayInformation.branded,
				displayInformation.onSale,
				displayInformation.feature
			);
		}
	}, [displayInformation]);

	const clickHandler = (link) => {
		router.push(link);
	};

	return (
		<>
			<section
				className={classes.main}
				style={{
					flexDirection:
						position === '1' ? 'row' : position === '2' ? 'row-reverse' : 'row',
				}}
			>
				<section
					className={classes.leftSection}
					style={
						displayInformation?.image && {
							backgroundImage: `url(${displayInformation?.image})`,
						}
					}
				>
					<div className={classes.titleBox}>
						<h2 className={classes.h2}>{displayInformation?.title}</h2>
						<p
							className={classes.link}
							onClick={() => clickHandler(displayInformation.link)}
						>
							{displayInformation?.linkText}
						</p>
					</div>
					{displayInformation?.credit ? (
						<div className={classes.credit}> {displayInformation.credit} </div>
					) : (
						''
					)}
				</section>
				<section className={classes.rightSection}>
					{displayListings.length < 1 ? (
						<>
							<PlaceHolderListingNew />
							<PlaceHolderListingNew />
							<PlaceHolderListingNew />
							<PlaceHolderListingNew />
						</>
					) : (
						displayListings.map((list, idx) => {
							return <Listing key={idx} listing={list} idx={idx} />;
						})
					)}
				</section>
			</section>
		</>
	);
};

export default DiscoverSection;
