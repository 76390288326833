"use client";
import axios from "axios"; // Make sure to install axios with `npm install axios`
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import classes from "./AboutUs.module.css";

const CountUpAnimation = ({ className, end, duration = 4 }) => {
  const [count, setCount] = useState(0);
  const nodeRef = useRef();
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Animate the number
            let start = 0;
            const endValue = parseInt(end);
            if (start === endValue) return;

            let totalDuration = duration * 1000;
            let startTimestamp = null;
            const step = (timestamp) => {
              if (!startTimestamp) startTimestamp = timestamp;
              const progress = Math.min(
                (timestamp - startTimestamp) / totalDuration,
                1
              );
              setCount(Math.floor(progress * (endValue - start) + start));
              if (progress < 1) {
                global?.window && window.requestAnimationFrame(step);
              }
            };
            global?.window && window.requestAnimationFrame(step);

            observer.current.unobserve(nodeRef.current);
          }
        });
      },
      {
        threshold: 1,
      }
    );

    observer.current.observe(nodeRef.current);

    return () => {
      if (observer.current && nodeRef.current) {
        observer.current.unobserve(nodeRef.current);
      }
    };
  }, [end, duration]);

  return (
    <div ref={nodeRef} className={className}>
      {count.toLocaleString()} lbs
    </div>
  );
};

const AboutUs = () => {
  const router = useRouter();

  const [landfillWeight, setLandfillWeight] = useState(null);

  const buttonHandler = () => {
    router.push("/about-us");
  };
  useEffect(() => {
    const fetchLandfillData = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_SERVER_API}/api/other/landfill`
        );
        if (response.data > 50000) {
          setLandfillWeight(response.data);
        } else {
          setLandfillWeight(110000);
        }
      } catch (error) {
        setLandfillWeight(100000);
      }
    };

    fetchLandfillData();
  }, []);

  return (
    <section className={classes.main}>
      <h2 className={classes.subTitle}>About Us</h2>
      <p className={classes.p}>
        In the heart of spring 2022, we—furniture lovers united by a shared
        dream—created Kashew. It&apos;s not just a marketplace; it&apos;s our
        cozy corner of the internet where every piece has a past and a place in
        your future. With over 9,000 hand-selected items, we&apos;re dedicated
        to making the experience of shopping pre-loved finds as delightful as
        the stories they hold. Join us in giving beautiful, pre-loved pieces a
        new home.
      </p>
      <div className={classes.p}>
        So far we&apos;ve kept{" "}
        {landfillWeight !== null ? (
          <CountUpAnimation className={classes.strong} end={landfillWeight} />
        ) : (
          "loading..."
        )}{" "}
        of furniture in circulation thanks to you!
      </div>
      <button onClick={buttonHandler} className={classes.button}>
        Learn More
      </button>
    </section>
  );
};

export default AboutUs;
